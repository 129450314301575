import { ultrabookAuth } from '@leyan/ultrabook-sdk';
import { message } from 'antd';
import axios from 'axios';
import qs from 'query-string';
import React, { useEffect, useState } from 'react';
import authorization from '../../config/authorization';
import { STORE_TOKEN_CACHE_KEY } from '../../config/constants';
import logger from '../../config/logger';
import { storeIdCache, superBookTokenCache } from '../../services/cache';

const SUPER_BOOK_AUTH_ENDPOINT = process.env.REACT_APP_SUPER_BOOK_AUTH_ENDPOINT;
const SUPER_BOOK_LOGIN = process.env.REACT_APP_SUPER_BOOK_LOGIN;
const LOGGER = logger.extend('SuperBookAuthorization');

function authorize(token: string) {
  localStorage.setItem(STORE_TOKEN_CACHE_KEY, token);
  authorization.onAuthorize?.(token, null);
}

function unauthorize() {
  localStorage.removeItem(STORE_TOKEN_CACHE_KEY);
  authorization.onUnauthorize?.('TOKEN_REMOVE', '请重新登录');
}

interface Props {
  children: React.ReactElement;
}

function SuperBookAuthorization(props: Props) {
  const { children } = props;
  const {
    access_token: superBookToken,
    platform,
    storeId,
  } = qs.parse(window.location.href.split('?')[1]);
  const [loading, setLoading] = useState<boolean>(!!superBookToken && !!platform && !!storeId);

  useEffect(() => {
    if (loading) {
      message.loading({
        content: '授权中...',
        duration: 0,
      });
    } else {
      message.destroy();
    }
  }, [loading]);

  const { watchTokenExpired, stopWatchTokenExpired } = ultrabookAuth({
    // 可选，默认为 window.location.origin
    subAppOrigin: window.location.origin,
    // 子应用域下token更新
    onRenewToken: (newToken) => {
      authorize(newToken);
    },
    // 错误
    onError: ({ data }) => {
      LOGGER.error('更新授权失败', data);
    },
  });

  const onFail = () => {
    message.error('授权失败');
    storeIdCache.remove();
    superBookTokenCache.remove();
    unauthorize();
    window.location.href = SUPER_BOOK_LOGIN as string;
  };

  useEffect(() => {
    if (platform && storeId && superBookToken) {
      axios
        .get(`${SUPER_BOOK_AUTH_ENDPOINT}/exchange_token?seller_id=${storeId}`, {
          headers: {
            authorization: `Bearer ${superBookToken}`,
          },
        })
        .then((res) => {
          authorize(res.data?.access_token);
            storeIdCache.set(storeId);
            superBookTokenCache.set(superBookToken);
            watchTokenExpired();
        })
        .catch((err) => {
          onFail();
        })
        .finally(() => {
          setLoading(false);
        });
    }
    return stopWatchTokenExpired;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [platform, storeId, superBookToken]);

  return loading ? null : children;
}

export default SuperBookAuthorization;
