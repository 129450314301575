import Cache from '../utils/Cache';

const PREFIX = process.env.REACT_APP_NAME || '';
const cache = new Cache(localStorage);
const [, , sid] = window.location.pathname.split('/');

export const storeIdCache = cache.cursor(`${PREFIX}/${sid}/storeId`);
export const superBookTokenCache = cache.cursor(`${PREFIX}/${sid}/superBookToken`);

export default cache;
