import React, { useCallback, useContext, useEffect, useState } from 'react';
import { unstable_batchedUpdates as batchedUpdates } from 'react-dom';

import refine, { Refinable } from '../../utils/refine';
import createNamedContext from '../../utils/createNamedContext';
import useTrackingRef from '../../hooks/useTrackingRef';
import AppLoading from '../AppLoading';

/**
 * 初始状态 context
 */
export const InitialStateContext = createNamedContext<unknown>('InitialState', undefined);

/**
 * 初始状态 hook
 */
export function useInitialState<T = unknown>() {
  const initialState = useContext(InitialStateContext);

  return initialState as T;
}

/**
 * 初始状态 api 选项
 */
export interface InitialStateApiHookOptions<T = unknown> {
  /**
   * 手动触发
   */
  manual?: boolean;
  /**
   * 是否已准备
   */
  ready?: boolean;
  /**
   * 是否跳过
   */
  skip?: boolean;
  /**
   * 失败事件处理函数
   * @param error 错误信息
   */
  onError?(error: Error): void;
  /**
   * 成功事件处理函数
   * @param initialState 初始状态
   */
  onSuccess?(initialState: T): void;
}

/**
 * 初始状态 api hook
 * @param getInitialState 获取初始状态
 * @param options 初始状态 api 选项
 */
export function useInitialStateApi<T = unknown>(
  getInitialState: () => T | PromiseLike<T>,
  options: InitialStateApiHookOptions = {},
) {
  const [loading, setLoading] = useState(() => {
    return !options.skip;
  });
  const [error, setError] = useState<Error | null>(null);
  const [initialState, setInitialState] = useState<T>();
  const optionsRef = useTrackingRef(options);
  const getInitialStateRef = useTrackingRef(getInitialState);
  const run = useCallback(async () => {
    try {
      const initialState = await getInitialStateRef.current();

      batchedUpdates(() => {
        setLoading(false);
        setError(null);
        setInitialState(initialState);
      });

      if (optionsRef.current.onSuccess) {
        optionsRef.current.onSuccess(initialState);
      }
    } catch (error: any) {
      batchedUpdates(() => {
        setLoading(false);
        setError(error);
      });

      if (optionsRef.current.onError) {
        optionsRef.current.onError(error);
      }
    }
  }, [getInitialStateRef, optionsRef]);

  useEffect(() => {
    if (!optionsRef.current.manual && !optionsRef.current.skip) {
      if (options.ready) {
        run();
      }
    }
  }, [options.ready, optionsRef, run]);

  return {
    loading,
    error,
    initialState,
    run,
  };
}

const { Provider: InitialStateProvider } = InitialStateContext;

/**
 * 初始状态容器属性
 */
export interface InitialStateProps<T = unknown> extends InitialStateApiHookOptions<T> {
  /**
   * 获取初始状态函数
   */
  getInitialState?(): T | PromiseLike<T>;
  /**
   * 加载替换元素
   */
  fallback?: Refinable<() => React.ReactNode>;
  /**
   * 子元素
   */
  children?: Refinable<(initialState: T) => React.ReactNode>;
}

/**
 * 初始状态容器
 * @param props 初始状态容器属性
 */
function InitialState<T = unknown>(props: InitialStateProps<T>) {
  const {
    getInitialState = () => ({}),
    manual,
    ready = true,
    skip,
    onSuccess,
    onError,
    fallback = <AppLoading />,
    children,
  } = props;

  const { loading, error, initialState } = useInitialStateApi(getInitialState, {
    manual,
    ready,
    skip,
    onSuccess,
    onError,
  });

  if (!onError && error) {
    throw error;
  }

  if (loading) {
    return <>{refine(fallback)}</>;
  }

  return (
    <InitialStateProvider value={initialState}>
      {refine(children, initialState as T)}
    </InitialStateProvider>
  );
}

export default InitialState;
