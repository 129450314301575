import { lazy } from 'react';
import { HomeOutlined, SettingOutlined, UserOutlined, PartitionOutlined } from '@ant-design/icons';

import { RouterConfig } from '../types';

/**
 * 404页面
 */
const NotFound = lazy(() => import('../views/NotFound'));
/**
 * 403页面
 */
const Forbidden = lazy(() => import('../views/Forbidden'));
/**
 * 主页面
 */
const Main = lazy(() => import('../views/Main'));
/**
 * 首页
 */
const Home = lazy(() => import('../views/Home'));
/**
 * 售前关怀
 */
const NewMarketing = lazy(() => import('../views/NewMarketing'));
/**
 * 营销设置
 */
const MarketingSetting = lazy(() => import('../views/MarketingSetting'));
/**
 * 用户标签
 */
const UserLabel = lazy(() => import('../views/Users/UserLabel'));
/**
 * 抖店用户画像
 */
const DdUsers = lazy(() => import('../views/Users/UserPortrait'));
/**
 * 抖店用户画像
 */
const GlobalSearch = lazy(() => import('../views/GlobalSearch'));
/**
 * Erp协同跟单
 */
const Erp = lazy(() => import('../views/Erp'));
/**
 * 服务反馈
 */
const Freedback = lazy(() => import('../views/Freedback'));

/**
 * 路由配置
 */
const routerConfig: RouterConfig = {
  history: 'hash',
  routes: [
    {
      path: '/403',
      element: <Forbidden />,
      helmet: {
        title: '403',
      },
    },
    {
      path: '/',
      element: <Main />,
      redirect: true,
      helmet: {
        defaultTitle: '抖店智能营销',
        titleTemplate: '%s - 抖店智能营销',
      },
      children: [
        {
          path: '/',
          helmet: {},
          element: <Home />,
          menu: {
            label: '首页',
            key: 'home',
            link: '/',
            icon: <HomeOutlined />,
          },
        },
        {
          path: '/globalSearch',
          helmet: {},
          element: <GlobalSearch />,
        },
        {
          path: '/userManger',
          helmet: {},
          menu: {
            label: '用户管理',
            key: 'userManger',
            icon: <UserOutlined />,
          },
          children: [
            {
              path: '/userLabel',
              element: <UserLabel />,
              helmet: {},
              menu: {
                key: 'userLabel',
                label: '用户标签',
                link: 'userManger/userLabel',
              },
            },
            {
              path: '/ddusers',
              element: <DdUsers />,
              helmet: {},
              menu: {
                key: 'ddusers',
                label: '抖店用户画像',
                link: 'userManger/ddusers',
              },
            },
          ],
        },
        {
          path: 'marketing',
          helmet: {},
          element: <NewMarketing />,
          menu: {
            label: '智能跟单',
            key: 'marketing',
            link: '/marketing',
            icon: <PartitionOutlined />,
          },
        },
        {
          path: '/activeCare',
          helmet: {},
          menu: {
            label: '售中后主动关怀',
            key: 'activeCare',
            icon: <UserOutlined />,
          },
          children: [
            {
              path: '/erp',
              element: <Erp />,
              helmet: {},
              menu: {
                key: 'erp',
                label: 'ERP协同关怀',
                link: 'activeCare/erp',
              },
            },
            {
              path: '/feedback',
              element: <Freedback />,
              helmet: {},
              menu: {
                key: 'feedback',
                label: '服务结果反馈',
                link: 'activeCare/feedback',
              },
            },
          ],
        },
        {
          path: 'marketingSetting',
          helmet: {},
          element: <MarketingSetting />,
          menu: {
            key: 'marketingSetting',
            label: '营销设置',
            link: '/marketingSetting',
            icon: <SettingOutlined />,
          },
        },
      ],
    },
    {
      path: '*',
      element: <NotFound />,
      helmet: {
        title: '404',
      },
    },
  ],
};

export default routerConfig;
