import { createContext } from 'react';
import { resolve, Response, storeHomeClient } from '../../../services';

export interface Config {
  version?: 'basic' | 'normal';
  header?: {
    // 顶部栏
    manual: {
      // 使用说明
      enable: boolean;
      url: string;
    };
  };
  sider?: {
    // 侧边栏
    title: {
      // 标题
      title: string;
      fontColor: string;
      titleBackgroundColor: string;
      titleImg?: string;
    };
    menuBackgroundColor: string; // 整体背景
    subMenuBackgroundColor: string; // 子菜单块背景
    menuItem: {
      hover: {
        // 悬浮样式
        backgrounColor: string;
        fontColor: string;
      };
      selected: {
        // 选中样式
        backgrounColor: string;
        fontColor: string;
      };
    };
  };
  defaultRouter?: string; // 默认路由
  routers?: Router[]; // 菜单权限
  subApp?: boolean; // 子应用权限
  whiteListFlag?: boolean; // version basic 基础版 添加了白名单
}
export interface Router {
  key: string; // 唯一标识
  label: string;
  enable: boolean; // 是否开启
  icon?: string;
}

export const ConfigContext = createContext<Config>({});

export function fetchConfig() {
  return resolve(storeHomeClient.get<Response<any>>('/store/v1/menu_config'));
}

export function fetchSubscribeData() {
  return resolve(storeHomeClient.get<Response<any>>('/store/v1/subscribe_info'));
}
