import { AxiosResponse } from 'axios';
import AxiosClient, { headerAuthorizeAxiosClient } from '../utils/AxiosClient';

export interface Response<T> {
  data: T;
  msg: string;
  // eslint-disable-next-line camelcase
  sub_code: number;
}

export function resolve<T>(promise: Promise<AxiosResponse<Response<T>>>) {
  return promise.then((response) => {
    return response.data.data;
  });
}

export function resolveResponseData<T>(resource: Promise<AxiosResponse<Response<T>>>) {
  return resource.then((response) => {
    return response.data;
  });
}

export const client = new AxiosClient({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
});

export const dataBoardClient = new AxiosClient({
  baseURL: process.env.REACT_APP_DATA_BOARD_API,
});

export const storeHomeClient = new AxiosClient({
  baseURL: process.env.REACT_APP_DY_STORE_HOME_API_ENDPOINT,
});

export const forbiddenWordClient = new AxiosClient({
  baseURL: process.env.REACT_APP_FORBIDDEN_WORD_API_ENDPOINT,
});

export const superBookAuthClient = new AxiosClient({
  baseURL: process.env.REACT_APP_SUPER_BOOK_AUTH_ENDPOINT,
});

export const fansHomeClient = new AxiosClient({
  baseURL: process.env.REACT_APP_FANS_HOME_ENDPOINT,
});

export const marketingHomeClient = new AxiosClient({
  baseURL: process.env.REACT_APP_MARKETING_HOME_ENDPOINT,
});

export function authorize(token: string) {
  headerAuthorizeAxiosClient(client, token, {
    headerName: process.env.REACT_APP_AUTH_HEADER,
    prefix: 'Bearer ',
  });

  headerAuthorizeAxiosClient(dataBoardClient, token, {
    headerName: process.env.REACT_APP_AUTH_HEADER,
    prefix: 'Bearer ',
  });

  headerAuthorizeAxiosClient(forbiddenWordClient, token, {
    headerName: process.env.REACT_APP_AUTH_HEADER,
    prefix: 'Bearer ',
  });

  headerAuthorizeAxiosClient(superBookAuthClient, token, {
    headerName: process.env.REACT_APP_AUTH_HEADER,
    prefix: 'Bearer ',
  });

  headerAuthorizeAxiosClient(storeHomeClient, token, {
    headerName: process.env.REACT_APP_AUTH_HEADER,
    prefix: 'Bearer ',
  });

  headerAuthorizeAxiosClient(fansHomeClient, token, {
    headerName: process.env.REACT_APP_AUTH_HEADER,
    prefix: 'Bearer ',
  });

  headerAuthorizeAxiosClient(marketingHomeClient, token, {
    headerName: process.env.REACT_APP_AUTH_HEADER,
    prefix: 'Bearer ',
  });
}
