import { authorize } from '../services';
import { AuthorizationConfig } from '../types';
import { STORE_TOKEN_CACHE_KEY } from './constants';
import logger from './logger';

const authorization: AuthorizationConfig = {
  cacheKey: STORE_TOKEN_CACHE_KEY,
  onAuthorize(token: string) {
    authorize(token);

    logger.debug('authorize with token: "%s"', token);
  },
  onUnauthorize(code: string, reason: string) {
    logger.warn('unauthorize (%s): "%s"', code, reason);
  },
  // async getInitialState() {
  //   const productInfo = await getProductInfo();
  //   return { productInfo };
  // },
};

export default authorization;
